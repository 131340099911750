import React from 'react';
import { motion } from 'framer-motion';
import Slideshow from './Slideshow';

const Cooking = () => {
  const images = [
    '/assets/cooking/japanese_breakfast.png',
    '/assets/cooking/souffle_pancake.jpeg',
    '/assets/cooking/paella.jpeg',
    '/assets/cooking/krapao_rice.jpeg',
    '/assets/cooking/panna_cotta.png',
    '/assets/cooking/okonomiyaki.jpeg',
    '/assets/cooking/mapo_noodles.png',
    '/assets/cooking/crudo.png',
    '/assets/cooking/galbi.png',
    '/assets/cooking/salmon_cutlet.png',
    '/assets/cooking/nanban.png',
  ];

  return (
    <motion.div
      className="max-w-4xl mx-auto p-5 text-gray-950"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-3xl font-bold mb-8">Cooking</h1>
      <Slideshow images={images} />
      <p className="text-xl mt-4">
        Being a child of two certified chefs, cooking has always been in my family's nature.
        I mainly cook Japanese food, but I do occasionally cook other cuisines to challenge myself as well.
        Here are some of the dishes that I have cooked recently!
      </p>
    </motion.div>
  );
};

export default Cooking;
