import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Slideshow = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (images.length === 0) return;

    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(timer);
  }, [images]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollWidth = scrollContainerRef.current.scrollWidth;
      const containerWidth = scrollContainerRef.current.clientWidth;
      const maxScroll = scrollWidth - containerWidth;
      const scrollPosition = (currentIndex / (images.length - 1)) * maxScroll;
      scrollContainerRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    }
  }, [currentIndex, images]);

  if (images.length === 0) {
    return <p className="text-xl mb-4">No images available</p>;
  }

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const getCurrentDishName = () => {
    const currentImage = images[currentIndex];
    const fileName = currentImage.split('/').pop().split('.')[0].replace(/_/g, ' ');
    return fileName.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <div className="w-full">
      <div className="relative w-full h-96 overflow-hidden rounded-lg mb-4">
        <AnimatePresence initial={false}>
          <motion.img
            key={currentIndex}
            src={images[currentIndex]}
            alt={`Cooking image ${currentIndex + 1}`}
            className="absolute w-full h-full object-contain"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          />
        </AnimatePresence>
      </div>
      <div className="text-center mb-4">
        <h2 className="text-2xl font-bold">{getCurrentDishName()}</h2>
      </div>
      <div 
        ref={scrollContainerRef}
        className="w-full overflow-x-auto whitespace-nowrap pb-2"
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        <div className="inline-flex space-x-2">
          {images.map((image, index) => (
            <div
              key={index}
              className={`w-20 h-20 flex-shrink-0 cursor-pointer ${
                index === currentIndex ? 'border-2 border-blue-500' : ''
              }`}
              onClick={() => handleThumbnailClick(index)}
            >
              <img
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className="w-full h-full object-cover rounded"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
